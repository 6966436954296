const requisiteAdapter = (data) => {
  return {
    title: data.title,
    text: data.text,
  };
};

export default ({ data }) => {
  return {
    requisites: data?.requisites.map(requisiteAdapter),
  };
};
